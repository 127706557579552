import React from "react"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    :root {
        --BaseFont: 16px;
        --black: #323B3E;
        --gray: #727576;
        --lightGray: #8C959F;
        --grayHover: #e3e5e670;
        --linkColor: #2533B3;
        --linkHover: #1F83BF;
        --highlighter: #FFFBC7;
        --StyledFont: courier-prime, courier, monospace;
        --SansFont: proxima-nova, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol";
        --transitionTime: 150ms;
        --standardSpacer: .5rem;
    }

    #___gatsby {
        height: 100%;
        width: 100vw;
        max-width: 100%;
    }

    body {
        font-family: var(--SansFont);
        font-size: var(--BaseFont);
        line-height: 1.3;
        color: var(--black);
        background-color: #fff;
    }

    a {
        font-weight: 400;
        color: var(--linkColor);

        &:hover {
        text-decoration: none;

        color: var(--linkHover);
        }
    }
`
const OuterContentCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  flex: 1 1 100%;
  justify-content: center;
  height: 95vh;
  width: 95vw;
`

const OuterContent = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <OuterContentCont>{children}</OuterContentCont>
    </>
  )
}

export default OuterContent
